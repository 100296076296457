import request from './../request.js'

export function importList(params){
    return request({
        url: 'card/importstatistics/list.do?',
        method: 'post',
        data:params
    })
}

export function getSetMealByCategory(params){
    return request({
        url: 'meal/setmeal/getSetMealByCategory.do?',
        method: 'post',
        data:params
    })
}

export function accountList(params){
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data:params
    })
}

export function importExcel(url,params){
    return request({
        url: url,
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//failList.vue
export function importListFail(params){
    return request({
        url: 'card/importstatisticsfail/list.do?',
        method: 'post',
        data:params
    })
}
export function findById(params){
    return request({
        url: 'card/importstatisticsfail/findById.do?',
        method: 'post',
        data:params
    })
}
export function update(params){
    return request({
        url: 'card/importstatisticsfail/update.do?',
        method: 'post',
        data:params
    })
}
